const header = {
  title: 'AM.',
}

const about = {
  name: 'Axel Martin',
  role: 'Backend & Smart Contract Software Engineer',
  social: {
    linkedin: 'https://www.linkedin.com/in/martinaxel/',
    github: 'https://github.com/paradoux',
  },
}

const projects = [
  {
    name: "iArt",
    description: 'AI based painting analysis tool',
    stack: ['Go', 'GPT-4', 'PostgreSQL', 'React.js', 'Tailwind.css'],
    livePreview: 'https://iart-front.onrender.com/',
  },
  {
    name: "Chainfluence",
    description: 'Winner of the Chainlink 2023 Hackathon',
    stack: ['Solidity', 'Hardhat', 'Polygon', 'Chainlink', 'ethers.js'],
    livePreview: 'https://devpost.com/software/chainfluence',
    sourceCode: 'https://github.com/BlockchainMarketing/ChainFluence',
  },
  {
    name: "Let's F*#@$* game",
    description: 'Button clicker game on the blockchain',
    stack: ['Solidity', 'Hardhat', 'Polygon', 'BNB Chain', 'ethers.js'],
    livePreview: 'https://lfgames.xyz/',
    sourceCode: 'https://github.com/lets-fucking-game/lets-fucking-game',
  },
  {
    name: 'SmartDeposit',
    description: 'Security deposit manager on the blockchain',
    stack: [
      'Solidity',
      'Hardhat',
      'Polygon',
      'Netlify Serverless Functions',
      'React',
      'ethers.js',
    ],
    livePreview: 'https://deposit-manager-front.vercel.app/',
    sourceCode: 'https://github.com/paradoux/deposit-manager',
  },
  {
    name: "AngularTalents",
    description: 'A reverse job board for angular developers',
    stack: ['Go', 'MongoDB', 'Angular.js'],
    livePreview: 'https://www.angulartalents.com/',
  },
  {
    name: 'DeVo',
    description:
      'Smart contract to keep track of how donations to NGOs are used. Implemented for the DeVo Protocol organization during the Web3athon week',
    stack: ['Solidity', 'Celo Network', 'ethers.js'],
    livePreview: 'https://devo.cydisys.com/',
  },
  {
    name: 'Easy Approve',
    description:
      'Simple UI to easily approve ERC20 token transfers by smart contracts on your behalf',
    stack: ['ethers.js', 'React', 'TypeScript'],
    sourceCode: 'https://github.com/paradoux/easy-approve',
    livePreview: 'https://easy-approve-paradoux.vercel.app/',
  },
]

const skills = ['Solidity', 'Golang', 'Node.js', 'React.js', 'TypeScript']

const contact = {
  email: 'mtn.axel@gmail.com',
}

export { header, about, projects, skills, contact }
